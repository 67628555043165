import { useQuery } from '@apollo/client'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  LoyaltyCardImage,
  LoyaltyCardName,
} from '@src/components/CustomerLoyaltyCard/CustomerLoyaltyCard.styles'
import { LoyaltyCardInfoText } from '@src/components/CustomerLoyaltyCard/LoyaltyCardInfoText'
import { getSingleLoyaltyCardDocument } from '@src/components/CustomerLoyaltyCard/queries/__generated__/getSingleLoyaltyCard.graphql-interface'
import { Content } from '@src/components/Drawer/Drawer.styles'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { ScallopedMaskSVG } from '@src/components/SVGS/ScallopedMaskSVG'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  LoyaltyCardContainer,
  LoyaltyCardHowManyStampsAwayText,
  LoyaltyInfoContainer,
  SectionContainer,
  SectionContent,
  SectionTitle,
  StyledLink,
  TextList,
} from './LoyaltyT&Cs.styles'

import { BurgerSection } from '../../Drawer/DrawerHeader'

const daysOfWeekMap = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']

export const LoyaltyTermsAndConditions: React.FC<{ discountId: string }> = ({
  discountId,
}) => {
  const { t } = useTranslation('customerLoyaltyCard')
  const marketplace = useMarketplace()
  const formatCurrency = useFormatCurrency()

  const { data, loading } = useQuery(getSingleLoyaltyCardDocument, {
    variables: {
      id: discountId,
    },
  })

  if (loading) {
    return <LoadingSpinner text="Getting Loyalty Card Details" />
  }

  if (!data) {
    return (
      <BurgerSection header={{ title: t('my_loyalty_cards') }}>
        <div>{t('error_fetching')}</div>
      </BurgerSection>
    )
  }

  const { discount } = data.singleLoyaltyCard
  const loyaltyCard = data.singleLoyaltyCard
  const loyaltyCardStamps = loyaltyCard.loyaltyCardStamps || []
  const sortedDays =
    discount?.daysOfWeek && [...discount.daysOfWeek].sort((a, b) => a - b)

  const getReadableDateRange = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return ''

    const dateFormat = 'MMM d, yyyy'
    const formattedStartDate = format(parseISO(startDate), dateFormat)
    const formattedEndDate = format(parseISO(endDate), dateFormat)

    return `${formattedStartDate} - ${formattedEndDate}`
  }

  return (
    <BurgerSection
      whiteBackgroundColor={true}
      header={{ title: 'Loyalty Card Details' }}
      noPadding={true}
      twoLineHeader={false}
    >
      <Content>
        <LoyaltyCardContainer>
          <ScallopedMaskSVG scale={1.5} />
          <LoyaltyCardImage
            isTermsPage={true}
            canStampBeEarned={true}
            role="img"
            aria-label={discount.id}
            imageUrl={imageJitURL(loyaltyCard.loyaltyCardIcon, {
              resize: {
                width: 87,
                height: 87,
                fit: 'cover',
              },
            })}
            scale={1.5}
          />
        </LoyaltyCardContainer>
        <LoyaltyCardName>{discount.name}</LoyaltyCardName>
        <LoyaltyInfoContainer>
          <LoyaltyCardInfoText
            loyaltyCard={loyaltyCard}
            isTermsPage={true}
            amountToSpendToEarnStamp={'0.00'}
          />
          {loyaltyCardStamps.length &&
          loyaltyCardStamps.length !== loyaltyCard.requiredStamps ? (
            <LoyaltyCardHowManyStampsAwayText>
              {t('how_many_stamps_away', {
                numberOfStamps: `${
                  loyaltyCard.requiredStamps - loyaltyCardStamps.length
                } stamp${
                  loyaltyCard.requiredStamps - loyaltyCardStamps.length !== 1
                    ? 's'
                    : ''
                } `,
              })}
            </LoyaltyCardHowManyStampsAwayText>
          ) : null}
        </LoyaltyInfoContainer>
        <SectionContainer>
          <SectionTitle>{t('earn_stamps_at')}</SectionTitle>
          {discount?.allowedOutlets && discount.allowedOutlets.length ? (
            discount.allowedOutlets.map((outlet, index) => (
              <TextList key={outlet.id}>
                <StyledLink
                  to={`/${marketplace.urlPath}/${outlet.id}/${convertToSlug(
                    outlet.name
                  )}/menu`}
                >
                  {outlet.name}
                </StyledLink>
                {discount.allowedOutlets &&
                  index < discount.allowedOutlets.length - 1 &&
                  ','}
              </TextList>
            ))
          ) : (
            <SectionContent>
              {marketplace.allOutletsText || t('earn_stamps_at_all')}
            </SectionContent>
          )}
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t('valid_dates')}</SectionTitle>
          <SectionContent>
            {discount?.startDate &&
              discount.endDate &&
              getReadableDateRange(discount.startDate, discount.endDate)}
          </SectionContent>
          <SectionContent>
            {sortedDays &&
              sortedDays.map((day, index) => (
                <TextList key={day}>
                  {daysOfWeekMap[day - 1]}
                  {index < sortedDays.length - 1 && ','}
                </TextList>
              ))}
          </SectionContent>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t('minimum_spend')}</SectionTitle>
          <SectionContent>
            {discount?.minimumSubtotalGross
              ? formatCurrency(discount.minimumSubtotalGross)
              : formatCurrency(0)}
          </SectionContent>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t('stamp_value')}</SectionTitle>
          <SectionContent>
            {discount?.discountPercentage
              ? `${discount.discountPercentage}%`
              : formatCurrency(discount?.discountAmount || 0)}
          </SectionContent>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>{t('other_info')}</SectionTitle>
          <SectionContent>{loyaltyCard.termsAndConditions}</SectionContent>
        </SectionContainer>
      </Content>
    </BurgerSection>
  )
}

import styled, { css } from 'styled-components'

import { IconButton } from '@src/components/Button/IconButton'
import { breakpoints } from '@src/constants/breakpoints'

export const LinkSlideContainer = styled.div`
  box-sizing: border-box;
  margin: 0 0 0 16px;
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.siteBackground};
  border-radius: 10px;
  width: 240px;
  display: flex;
  flex-direction: column;

  background-color: rgba(200, 200, 200, 0.3);

  @media (min-width: ${breakpoints.tablet}px) {
    width: 298px;
    margin: 0 0 0 28px;
  }

  @media (hover: hover) and (pointer: fine) {
    transition: 0.3s ease;
    transition-property: border-color;

    &:hover {
      border-color: ${({ theme }) => theme.colors.brandHighlight};
    }
  }
  overflow: hidden;
`

export const LinkSlideContent = styled.div<{ image?: string }>`
  height: 290px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  &:hover {
    .image {
      transform: scale(1.2); /* Adjust the scale as needed */
    }
  }
  ${props =>
    props.image &&
    css`
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${props.image});
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease-in-out;
        z-index: 1;

        overflow: hidden;
      }
    `};
  .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    width: 240px;
    height: 254px;
  }
`

export const LinkSlideTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
`

export const LinkSlideSpacer = styled.div`
  flex-grow: 2;
`

export const LinkSlideDetailsContainer = styled.div`
  padding: 0px 16px 8px 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(31, 31, 31, 0.75);
  color: #fff;
  border-radius: 0 0 10px 10px;
  min-height: 106px;
`

export const LinkSlideDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  color: #fff;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 0px;
  }
`

export const MoreLinkContent = styled.span`
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const MoreLinkSvg = styled(IconButton)`
  color: #fff;
  border: 2px solid #fff;
  margin-right: 8px;
  width: 30px;
  height: 30px;
`

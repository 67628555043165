import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const SkeletonWrapper = styled.div`
  margin-top: 55px;
`

export const SkeletonBackButtonContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 5px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    position: absolute;
    margin-left: 26px;
    margin-top: 38px;
  }
`

export const SkeletonHeaderContainer = styled.div`
  display: flex;
  height: 200px;

  padding: 0px 32px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding: 16px 32px;
    align-items: flex-start;
    justify-content: space-between;
  }
`

export const SkeletonHeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-left: 30px;
  }
`

export const TitleAndPillContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`
export const SkeletonFulfilmentSection = styled.div`
  width: 100%;
  border: 2px solid rgba(150, 150, 150, 0.1);
  padding: 12px 32px 12px 32px;
`
export const SkeletonNavBar = styled.div`
  width: 100%;
  padding: 12px 32px 12px 32px;
`

export const SkeletonDealsContainer = styled.div`
  padding: 12px 32px 12px 32px;
  border-top: 2px solid rgba(150, 150, 150, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
`

export const SkeletonMenuContainer = styled.div`
  padding: 36px 32px 12px 32px;
  border-top: 16px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const SkeletonMenuItemContainer = styled.div`
  display: flex;
  margin-right: 35px;
  margin-top: 50px;
`

export const SkeletonMenuDetails = styled.div`
  margin-right: 12px;
  display: flex;
  flex-direction: column;
`

export const SkeletonMenuLogoAndPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const PriceContainer = styled.div`
  margin-top: 20px;
`

export const MenuDetailSingleRow = styled.div`
  margin-top: 10px;
`

export const MenuFlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const DealsAndOffersCardContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardOuterContainer = styled.div`
  margin-right: 24px;
`

import styled, { css } from 'styled-components'

import { DoubleArrowSVG } from '@src/components/SVGS/DoubleArrowSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  showSearchBar: boolean
  startAnimation: boolean
}>`
  display: flex;
  height: 64px;
  margin: 0;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${({ showSearchBar }) =>
    showSearchBar &&
    css`
      animation: disappearLeft 1s;
      opacity: 0;
      max-width: 90%;
    `}

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation: disappearRight 1s;
    `}

  @keyframes disappearLeft {
    from {
      opacity: 1;
      right: 0;
    }
    to {
      opacity: 0;
      right: 100%;
    }
  }

  @keyframes disappearRight {
    from {
      opacity: 0;
      right: 100%;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
`

export const OuterContainer = styled.div`
  position: relative;
  height: 64px;
  width: 100%;

  @media (max-width: ${breakpoints.tabletMisc}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const InnerContainer = styled.div<{
  isActive: boolean
  disabled?: boolean
}>`
  @media (max-width: ${breakpoints.tabletMisc}px) {
    margin: 0 90px 0 8px;
  }
  font-size: 15px;
  font-weight: 700;
  padding-left: 24px;
  width: 100%;
  border-radius: 24px;
  height: 44px;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  border: 2px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.brandHighlight : theme.colors.darkGrey};
  cursor: pointer;
  color: ${({ isActive, theme, disabled }) =>
    isActive && !disabled
      ? theme.colors.brandHighlight
      : theme.colors.mainText};

  &:hover {
    border: 2px solid
      ${({ isActive, theme }) =>
        isActive ? theme.colors.brandHighlight : theme.colors.brand};
    color: ${({ isActive, theme, disabled }) =>
      isActive && !disabled ? theme.colors.brandHighlight : theme.colors.brand};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: null;
      opacity: 0.2;
      cursor: not-allowed;
      &:hover {
        background-color: null;
      }
      padding-right: 44px;
    `}
`

export const IconContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    margin: 0px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}
`

export const StyledDoubleArrowSVG = styled(DoubleArrowSVG)`
  margin-left: 12px;
  padding-right: 20px;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-right: 24px;
  }
`

export const ExpandButton = styled.button<{
  $display: boolean
  $isMenuView?: boolean
}>`
  width: 60px;
  height: 48px;
  ${({ $isMenuView }) =>
    !$isMenuView &&
    css`
      right: 64px;
      top: 8px;
      position: absolute;
    `};

  z-index: 1;
  ${({ $display }) =>
    !$display &&
    css`
      display: hidden;
    `};

  background-color: transparent;
  cursor: pointer;
  border: none;
`
export const MobileSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  padding: 8px 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 8px 32px 0px 32px;
  }
`

export const LoyaltyCardContainer = styled.div`
  padding: 0px 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 8px 32px 0px 32px;
  }
`

export const Description = styled.p`
  font-size: 18px;
  font-weight: 700;
`

export const InnerContainer = styled.main`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ebecf0;
  padding: 16px;
`

export const SubHeader = styled.h2`
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`

export const Text = styled.p`
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
`

export const Label = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px;
  height: 20px;
`

import React from 'react'

import { LoyaltyCard as LoyaltyCardType } from '@src/components/SingleOutlet/types'
import { UseBasketTotalsResponseData } from '@src/hooks/useTotals/useBasketTotals'

import {
  Stamp,
  StampsContainer,
  StampProgressMeter,
  StampAndProgressMeterContainer,
  StampAnimation,
  StampProgressMeterAnimation,
} from './CustomerLoyaltyCard.styles'

export const LoyaltyCardStampProgressMeter: React.FC<{
  loyaltyCard: LoyaltyCardType | undefined
  isOutletList?: boolean
  canStampBeEarned?: boolean
  basketTotals?: UseBasketTotalsResponseData
}> = ({
  loyaltyCard,
  isOutletList = false,
  canStampBeEarned = true,
  basketTotals,
}) => {
  if (!loyaltyCard) {
    return null
  }

  return (
    <StampsContainer>
      {Array.from({
        length: loyaltyCard.requiredStamps,
      }).map((item, index) => (
        <StampAndProgressMeterContainer
          key={`${item}${index}`}
          canStampBeEarned={basketTotals ? canStampBeEarned : true}
        >
          <Stamp
            isOutletList={isOutletList}
            smallStamp={loyaltyCard.requiredStamps >= 7}
            loyaltyCardColor={loyaltyCard.loyaltyCardColor || '#00956a'}
            hasStampBeenUsed={
              (loyaltyCard.loyaltyCardStamps &&
                loyaltyCard.loyaltyCardStamps.length > index) ||
              false
            }
            key={`${item}${index}`}
          >
            {canStampBeEarned && basketTotals && (
              <StampAnimation
                isOutletList={isOutletList}
                smallStamp={loyaltyCard.requiredStamps >= 7}
                loyaltyCardColor={loyaltyCard.loyaltyCardColor || '#00956a'}
                showAnimation={loyaltyCard?.loyaltyCardStamps?.length === index}
                singleStamp={!loyaltyCard?.loyaltyCardStamps?.length}
                key={`${item}${index}`}
              />
            )}
          </Stamp>
          {index + 1 < loyaltyCard.requiredStamps && (
            <StampProgressMeter
              loyaltyCardColor={loyaltyCard.loyaltyCardColor || '#00956a'}
              hasStampBeenUsed={
                (loyaltyCard.loyaltyCardStamps &&
                  loyaltyCard.loyaltyCardStamps.length - 1 > index) ||
                false
              }
            >
              {canStampBeEarned && basketTotals && (
                <StampProgressMeterAnimation
                  loyaltyCardColor={loyaltyCard.loyaltyCardColor || '#00956a'}
                  showAnimation={
                    (loyaltyCard.loyaltyCardStamps &&
                      loyaltyCard.loyaltyCardStamps.length > index) ||
                    false
                  }
                  key={`${item}${index}`}
                />
              )}
            </StampProgressMeter>
          )}
        </StampAndProgressMeterContainer>
      ))}
    </StampsContainer>
  )
}

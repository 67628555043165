import React from 'react'
import { useTranslation } from 'react-i18next'

import { ScallopedMaskSVG } from '@src/components/SVGS/ScallopedMaskSVG'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { imageJitURL } from '@src/utils/imageJitURL'

import { singleOrderCustomType } from './queries/getSingleOrderCustomType'
import {
  DeliveryContainer,
  BoldTitle,
  LightText,
  LoyaltyCardImage,
  StampInfo,
  InfoAndIconContainer,
} from './SingleOrder.styles'

export const LoyaltyCardStamps: React.FC<{
  myOrder: singleOrderCustomType
}> = ({ myOrder }) => {
  const { t } = useTranslation('singleOrder')
  const formatCurrency = useFormatCurrency()

  const { loyaltyCardStampInfo } = myOrder

  if (!loyaltyCardStampInfo?.length) {
    return null
  }

  return (
    <>
      <DeliveryContainer>
        <BoldTitle>{t('stamps_earned')}</BoldTitle>
        <LightText>
          {loyaltyCardStampInfo?.map((data, index) => (
            <InfoAndIconContainer key={`${data.loyaltyCardName}-${index}`}>
              <LoyaltyCardImage
                imageUrl={imageJitURL(data.loyaltyCardIcon, {
                  resize: {
                    width: 68,
                    height: 68,
                    fit: 'cover',
                  },
                })}
              />
              <ScallopedMaskSVG scale={0.42} />
              <StampInfo>{data.loyaltyCardName}</StampInfo>
              <StampInfo>{formatCurrency(data.amountEarnedOnOrder)}</StampInfo>
              {data.stampsEarnedUpToThisOrder !== 0 && (
                <StampInfo>{data.totalStampsText}</StampInfo>
              )}
            </InfoAndIconContainer>
          ))}
        </LightText>
      </DeliveryContainer>
    </>
  )
}

import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'

import { OutletMenuSearch } from '@src/components/SiteHeader/SearchBar/OutletMenuSearch/OutletMenuSearch'
import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  Capsule,
  CapsuleContainer,
  Container,
  FilterOverlay,
  OutletMenuSearchContainer,
} from './OutletMenuSection.styles'

import { OutletMenuItemGroupTree } from '../menuItemGroupTreeType'

export const OutletMenuSection: React.FC<{
  parentMenus: Pick<
    OutletMenuItemGroupTree[number]['parentMenu'],
    'id' | 'name'
  >[]
  activeMenuId: string | null
  setActiveMenuId: (val: string | null) => void
  setManualNavigation: (manualNavigation: boolean) => void
  isCollapsingDivVisible: boolean
  outletId: string
  showMenuSearchResults: boolean
  setMenuSearchIds: (menuSearchIds: string[]) => void
  setShowMenuSearchResults: (showMenuSearchResults: boolean) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}> = ({
  parentMenus,
  activeMenuId,
  setActiveMenuId,
  isCollapsingDivVisible,
  setManualNavigation,
  outletId,
  setMenuSearchIds,
  searchTerm,
  showMenuSearchResults,
  setShowMenuSearchResults,
  setSearchTerm,
}) => {
  const { width } = useReactiveVar(screenResolutionVar)

  const containerRef = useRef<HTMLDivElement>(null)
  const activeElementRef = useRef<HTMLButtonElement | null>(null)
  const [searchOpen, setSearchOpen] = useState(false)

  useEffect(() => {
    if (activeMenuId && width <= breakpoints.tablet) {
      const element = activeElementRef.current
      if (containerRef.current && element) {
        const activeElPosition = element.getBoundingClientRect()
        containerRef.current.scrollTo({
          left: activeElPosition.left,
          behavior: 'smooth',
        })
      }
    }
  }, [activeMenuId, width])

  return (
    <>
      {width < breakpoints.wideDesktop && <FilterOverlay />}
      <Container>
        <CapsuleContainer ref={containerRef}>
          {((!searchOpen && !showMenuSearchResults) ||
            width > breakpoints.phablet) &&
            parentMenus.map(parentMenu => (
              <Capsule
                onClick={() => {
                  if (activeMenuId !== parentMenu.id) {
                    setActiveMenuId(parentMenu.id)
                    // clicking on a menu nav item causes conflicts with the intersection observer
                    setManualNavigation(true)
                  }
                  const el = document.getElementById(parentMenu.id)
                  if (el) {
                    if (!isCollapsingDivVisible) {
                      const boundingClientRect = el.getBoundingClientRect()
                      const topOfMenuSection =
                        boundingClientRect.top + window.pageYOffset - 80
                      window.scrollTo({
                        top: topOfMenuSection,
                        behavior: 'smooth',
                      })
                    } else {
                      el.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'nearest',
                      })
                    }
                  }
                  setTimeout(() => {
                    setManualNavigation(false)
                  }, 500)
                }}
                active={activeMenuId === parentMenu.id}
                key={parentMenu.id}
                ref={el => {
                  if (activeMenuId === parentMenu.id) {
                    activeElementRef.current = el
                  }
                }}
              >
                {parentMenu.name}
              </Capsule>
            ))}
        </CapsuleContainer>
        <OutletMenuSearchContainer
          onFocus={() => setSearchOpen(true)}
          onBlur={() => setSearchOpen(false)}
        >
          <OutletMenuSearch
            outletId={outletId}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setMenuSearchIds={setMenuSearchIds}
            showMenuSearchResults={showMenuSearchResults}
            setShowMenuSearchResults={setShowMenuSearchResults}
          />
        </OutletMenuSearchContainer>
      </Container>
    </>
  )
}

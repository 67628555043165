export const outletCard = {
  minimum_order: 'Minimum Order',
  no_minimum_order: 'No Minimum Order',
  x_offers_available: '{{discountCount}} Offers Available',
  num_of_offers: '{{discountCount}} Offers',
  x_offers: '{{discountCount}} x OFFERS',
  x_offer: '{{discountCount}} x OFFER',
  delivery: 'Delivery',
  collection: 'Collection',
  table: 'Table Service',
  find_out_more: 'Find out more!',
}

import React, { useEffect } from 'react'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useLazyOutletAvailabilityQuery } from '@src/hooks/sharedQueries/outletAvailabilityQuery/useOutletAvailabilityQuery'

import { OutletPage } from './OutletPage'

const ONE_MINUTE = 60000

export const OutletPageStateWrapper: React.FC = () => {
  const {
    outlet,
    updateFulfilmentTimesIfExpired,
    data: {
      currentFulfilment: { narrowType: currentFulfilmentNarrowType },
    },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
    reinitialiseIfNotBasket: true,
  })

  const [outletAvailabilityQuery] = useLazyOutletAvailabilityQuery()

  const fetchOutletAvailability = () => {
    void outletAvailabilityQuery({
      variables: {
        outletId: outlet.id,
        fulfilmentMethod: currentFulfilmentNarrowType,
      },
      fetchPolicy: 'network-only',
    })
  }

  // these 2 useEffects do an initial update of the current outlet's fulfilment times
  // and then updates them every minute, to ensure expired times are updated, and to refetch the outlet's availability if it should have reopened
  useEffect(() => {
    // run on first load in case of in case of expired basket times
    if (outlet.isOrderable) {
      updateFulfilmentTimesIfExpired()
    } else if (outlet.nextOpenDate.valueOf() < Date.now()) {
      fetchOutletAvailability()
    }
  }, [outlet.isOrderable, outlet.nextOpenDate])
  useEffect(() => {
    // this useEffect is separate to the initial one above as the `updateFulfilmentTimesIfExpired`
    // function gets redefined as a result of `updateFulfilmentTimesIfExpired` being called
    // and we don't want to get in a loop
    // so don't add anything else to this useEffect
    const interval = setInterval(() => {
      if (outlet.isOrderable) {
        updateFulfilmentTimesIfExpired()
      } else if (outlet.nextOpenDate.valueOf() < Date.now()) {
        fetchOutletAvailability()
      }
    }, ONE_MINUTE)
    return () => clearInterval(interval)
  }, [outlet.isOrderable, updateFulfilmentTimesIfExpired])

  return <OutletPage />
}

import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

export const Container = styled.div`
  background-color: white;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding: 8px 18px 0px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;
  ${removeScrollbar};
`
export const NonIdealSearchContainer = styled.div`
  padding: 8px 18px 0px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 16px 32px 0;
  }
`
export const HeaderContainer = styled.div`
  padding: 16px 16px 0 16px;
  border-top: 8px solid rgba(0, 0, 0, 0.1);

  @media (min-width: ${breakpoints.tablet}px) {
    border-top: 16px solid rgba(0, 0, 0, 0.1);
    padding: 32px 32px 0px 32px;
  }
`
export const Header = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0;
  color: #2e3333;

  @media (min-width: ${breakpoints.largeTabletMisc}px) {
    margin-bottom: 8px;
    font-size: 24px;
  }
`

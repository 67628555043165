import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { breakpoints } from '@src/constants/breakpoints'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { screenResolutionVar } from '@src/models/screenResolution'
import { TreeOutletMenuItemGroup } from '@src/pages/OutletPage/menuItemGroupTreeType'

import {
  ItemContainer,
  ItemsContainer,
  Header,
  HeaderContainer,
  Description,
  ObservableContainer,
  ScrollToContainer,
  SubMenuHeader,
} from './MenuGroup.styles'

import { isFinalElementInBreakpoint } from '../isFinalElementInBreakpoint'
import { MenuItem } from '../MenuItem/MenuItem'

export const MenuGroup: React.VFC<{
  outletId: string
  activeMenuId: string | null
  setActiveMenuId: (val: string) => void
  outletParentMenu: TreeOutletMenuItemGroup
  outletSubMenus: TreeOutletMenuItemGroup[]
  manualNavigation: boolean
  hideName?: boolean
}> = ({
  outletId,
  outletParentMenu,
  outletSubMenus,
  activeMenuId,
  setActiveMenuId,
  manualNavigation,
  hideName,
}) => {
  const { width } = useReactiveVar(screenResolutionVar)
  const { displayPermanentCheckout } = useCheckoutRouter()

  const rootMargin = (): string => {
    if (width > breakpoints.superWideDesktop) {
      return `${-window.innerHeight * 0.7}px`
    }
    if (width > breakpoints.tablet) {
      return `${-window.innerHeight * 0.65}px`
    } else return `${-window.innerHeight * 0.55}px`
  }

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: `0px 0px  ${rootMargin()}
     0px`,
    threshold: 1,
    trackVisibility: true,
    delay: 300,
  })

  useEffect(() => {
    if (
      inView &&
      entry?.isIntersecting &&
      activeMenuId !== outletParentMenu.id &&
      !manualNavigation
    ) {
      setActiveMenuId(outletParentMenu.id)
    }
  }, [ref, inView])

  return (
    <div>
      <ScrollToContainer id={outletParentMenu.id} aria-hidden />
      <ObservableContainer ref={ref} aria-hidden />
      <HeaderContainer hasDescription>
        {!hideName && <Header hasDescription>{outletParentMenu.name}</Header>}
        {outletParentMenu.description && (
          <Description>{outletParentMenu.description}</Description>
        )}
      </HeaderContainer>
      <ItemsContainer>
        {outletParentMenu.outletMenuItems.map(({ id, menuItemId }, i) => (
          <ItemContainer key={id}>
            <MenuItem
              finalElement={isFinalElementInBreakpoint(
                outletParentMenu.outletMenuItems.length,
                i,
                width,
                displayPermanentCheckout
              )}
              outletId={outletId}
              menuItemId={menuItemId}
              dataTestId={`menu-item-${i}`}
            />
          </ItemContainer>
        ))}
      </ItemsContainer>
      {outletSubMenus.map(subMenu => (
        <div key={subMenu.id}>
          <SubMenuHeader>
            {subMenu.name}
            {subMenu.description && (
              <Description>{subMenu.description}</Description>
            )}
          </SubMenuHeader>
          <ItemsContainer>
            {subMenu.outletMenuItems.map(({ id, menuItemId }, index) => {
              return (
                <ItemContainer key={id}>
                  <MenuItem
                    outletId={outletId}
                    menuItemId={menuItemId}
                    finalElement={isFinalElementInBreakpoint(
                      subMenu.outletMenuItems.length,
                      index,
                      width,
                      displayPermanentCheckout
                    )}
                  />
                </ItemContainer>
              )
            })}
          </ItemsContainer>
        </div>
      ))}
    </div>
  )
}

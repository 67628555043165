import { camelCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  LinkSlideContainer,
  LinkSlideContent,
  LinkSlideDescription,
  LinkSlideDetailsContainer,
  LinkSlideSpacer,
  LinkSlideTitle,
  MoreLinkContent,
  MoreLinkSvg,
} from '@src/components/LinkSlide/LinkSlide.styles'
import { InfoContainer } from '@src/components/SingleOutlet/SingleOutlet.styles'
import { TextClamp } from '@src/components/Text/Clamp'
import { ClampWithHover } from '@src/components/Text/Clamp.styles'
import { baseUrlImages } from '@src/config/urls'
import { LinkCard } from '@src/graphql-types'
import { useMarketplace } from '@src/hooks/useMarketplace'

export const LinkSlide: React.FC<{
  linkCard: LinkCard
}> = ({ linkCard }) => {
  const { t } = useTranslation(['outletCard'])

  const marketplace = useMarketplace()

  let link = ''

  if (linkCard.menuItem && linkCard.outlet) {
    link = `/${marketplace.urlPath}/${linkCard.outlet.id}/${camelCase(
      linkCard.outlet.displayName
    )}/menu?menuItem=${linkCard.menuItem.id}`
  } else if (linkCard.outlet) {
    link = `/${marketplace.urlPath}/${linkCard.outlet.id}/${camelCase(
      linkCard.outlet.displayName
    )}`
  } else if (linkCard.url) {
    if (linkCard.url.startsWith('http:') || linkCard.url.startsWith('https:')) {
      link = linkCard.url
    } else {
      link = `http://${linkCard.url}`
    }
  }

  return (
    <LinkSlideContainer>
      <a href={link}>
        <LinkSlideContent image={baseUrlImages + '/' + linkCard.image}>
          <div className="image"></div>
          <div className="content">
            <LinkSlideSpacer />
            {(linkCard.name?.length ||
              (linkCard.buttonText?.length ?? 0) > 0) && (
              <LinkSlideDetailsContainer>
                <InfoContainer>
                  <LinkSlideTitle>
                    <TextClamp>{linkCard.name}</TextClamp>
                  </LinkSlideTitle>
                  {!!linkCard.description?.length && (
                    <LinkSlideDescription>
                      <ClampWithHover
                        clamp={(linkCard.buttonText?.length ?? 0) > 0 ? 2 : 4}
                        onHoverShow={true}
                      >
                        {linkCard.description}
                      </ClampWithHover>
                    </LinkSlideDescription>
                  )}
                </InfoContainer>

                {(linkCard.buttonText?.length ?? 0) > 0 && (
                  <MoreLinkContent>
                    <MoreLinkSvg
                      className={'moreLinkIcon'}
                      size={18}
                      icon={'caretRight'}
                      alt={t('right')}
                    />{' '}
                    {linkCard.buttonText}
                  </MoreLinkContent>
                )}
              </LinkSlideDetailsContainer>
            )}
          </div>
        </LinkSlideContent>
      </a>
    </LinkSlideContainer>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperType } from 'swiper/types'

import { CustomerDetailsAndAddresses_customerDetails_loyaltyCards } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddresses'

import { LoyaltyCard } from './CustomerLoyaltyCard'
import { LoyaltyCardSliderContainer } from './CustomerLoyaltyCard.styles'

import {
  ArrowContainer,
  HeaderContainer,
} from '../ActiveOrdersSlider/ActiveOrdersSlider.styles'
import { GroupHeader } from '../BurgerMenu/AccountMain/AccountNavigationLinks.styles'
import { IconButton } from '../Button/IconButton'
import { Carousel } from '../Carousel'

export const CustomerLoyaltyCardSlider: React.FC<{
  loyaltyCards:
    | CustomerDetailsAndAddresses_customerDetails_loyaltyCards[]
    | null
  isAccountPage: boolean
  basketTotal?: number
}> = ({ loyaltyCards }) => {
  const { t } = useTranslation('customerLoyaltyCard')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loyaltyCardSwiperInstance, setLoyaltyCardSwiperInstance] =
    useState<SwiperType | null>(null)

  if (!loyaltyCards) {
    return null
  }

  const scrollToNextCard = () => {
    const indexToScrollTo = currentIndex + 1
    setCurrentIndex(indexToScrollTo)
    loyaltyCardSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  const scrollToPreviousCard = () => {
    const indexToScrollTo = currentIndex - 1
    setCurrentIndex(indexToScrollTo)
    loyaltyCardSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  return (
    <>
      {loyaltyCards.length > 0 && (
        <HeaderContainer>
          <GroupHeader>{t('my_loyalty_cards')}</GroupHeader>
          {loyaltyCards.length > 1 && (
            <ArrowContainer>
              <IconButton
                disabled={currentIndex === 0}
                onClick={() => {
                  if (currentIndex > 0) {
                    scrollToPreviousCard()
                  }
                }}
                size={32}
                icon={'caretLeft'}
                alt={t('left')}
              />
              <IconButton
                disabled={currentIndex === loyaltyCards.length - 1}
                onClick={() => {
                  if (currentIndex !== loyaltyCards.length - 1) {
                    scrollToNextCard()
                  }
                }}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </ArrowContainer>
          )}
        </HeaderContainer>
      )}
      <LoyaltyCardSliderContainer>
        <Carousel
          allowTouchMove={false}
          setSwiperInstance={setLoyaltyCardSwiperInstance}
          slides={loyaltyCards.map(loyaltyCard => {
            return (
              <LoyaltyCard
                loyaltyCard={loyaltyCard}
                key={`${loyaltyCard.id}`}
              />
            )
          })}
        />
      </LoyaltyCardSliderContainer>
    </>
  )
}

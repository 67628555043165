import { useReactiveVar } from '@apollo/client'
import { useTransition } from '@react-spring/web'
import React, { useState, useEffect } from 'react'

import { MarketingPreferences } from '@src/components/BurgerMenu/MarketingPreferences/MarketingPreferences'
import { Allergy } from '@src/components/BurgerMenu/Policies/Allergy'
import { breakpoints } from '@src/constants/breakpoints'
import {
  AddressesChildName,
  DetailsChildName,
  LoginChildName,
  MainRouteName,
  RoutingParams,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'
import { screenResolutionVar } from '@src/models/screenResolution'
import { PaymentDetails } from '@src/pages/Account/Payment/PaymentDetails'
import { MyOrders } from '@src/pages/MyOrders/MyOrders'
import { SingleOrder } from '@src/pages/SingleOrder/SingleOrder'

import {
  AnimationHider,
  AnimatedContainer,
  StyledScrollBars,
} from './AnimatedSwitch.styles'

import { AccountMain } from '../AccountMain/AccountMain'
import { AddAddress } from '../AddAddress/AddAddress'
import { AddBusiness } from '../AddBusiness/AddBusiness'
import { AddressBook } from '../AddressBook/AddressBook'
import { bigPanel } from '../BigPanel'
import { ChangePassword } from '../ChangePassword/ChangePassword'
import { ContactDetails } from '../ContactDetails/ContactDetails'
import { EditAddress } from '../EditAddress/EditAddress'
import { Login } from '../Login/Login'
import { PasswordReset } from '../Login/PasswordReset'
import { LoyaltyTermsAndConditions } from '../LoyaltyTermsAndConditions/LoyaltyT&Cs'
import { Cookies } from '../Policies/Cookies'
import { HelpAndSupport } from '../Policies/HelpAndSupport'
import { Misc } from '../Policies/Misc'
import { Privacy } from '../Policies/Privacy'
import { Terms } from '../Policies/Terms'
import { Register } from '../Register/Register'

const AnimatedLocation: React.FC<{
  styles: Record<string, any>
  useLeftOffset: boolean
}> = ({ styles, useLeftOffset }) => {
  const { route } = useAccountRouter()
  const isBigPanel = route?.mainRouteName
    ? bigPanel.includes(route?.mainRouteName)
    : false

  if (route?.mainRouteName === MainRouteName.LOYALTY && !route.pageDataId) {
    return null
  }

  return (
    <AnimatedContainer
      style={styles}
      useLeftOffset={useLeftOffset}
      bigPanel={isBigPanel}
    >
      <StyledScrollBars>
        {route?.mainRouteName === MainRouteName.MENU && <AccountMain />}
        {/* Contact Detail Route */}
        {route?.mainRouteName === MainRouteName.DETAILS &&
          !route.childRouteName && <ContactDetails />}
        {route?.mainRouteName === MainRouteName.DETAILS &&
          route.childRouteName === DetailsChildName.PASSWORD_CHANGE && (
            <ChangePassword />
          )}

        {/* Order Routes */}
        {route?.mainRouteName === MainRouteName.ORDERS && route.pageDataId && (
          <SingleOrder orderId={route.pageDataId} />
        )}
        {route?.mainRouteName === MainRouteName.ORDERS &&
          !route?.pageDataId && <MyOrders />}
        {/* Address Routes */}
        {route?.mainRouteName === MainRouteName.ADDRESSES &&
          !route?.childRouteName && <AddressBook />}
        {route?.mainRouteName === MainRouteName.ADDRESSES &&
          route?.childRouteName === AddressesChildName.ADD && <AddAddress />}
        {route?.mainRouteName === MainRouteName.ADDRESSES &&
          route?.childRouteName === AddressesChildName.EDIT &&
          route?.pageDataId && <EditAddress addressId={route.pageDataId} />}

        {route?.mainRouteName === MainRouteName.LOYALTY && route.pageDataId && (
          <LoyaltyTermsAndConditions discountId={route.pageDataId} />
        )}

        {route?.mainRouteName === MainRouteName.MARKETING && (
          <MarketingPreferences />
        )}
        {route?.mainRouteName === MainRouteName.PAYMENT && <PaymentDetails />}
        {route?.mainRouteName === MainRouteName.TERMS && <Terms />}
        {route?.mainRouteName === MainRouteName.HELP && <HelpAndSupport />}
        {route?.mainRouteName === MainRouteName.PRIVACY && <Privacy />}
        {route?.mainRouteName === MainRouteName.ALLERGY && <Allergy />}
        {route?.mainRouteName === MainRouteName.COOKIES && <Cookies />}
        {route?.mainRouteName === MainRouteName.MISC && <Misc />}
        {route?.mainRouteName === MainRouteName.ADD_BUSINESS && <AddBusiness />}
        {route?.mainRouteName === MainRouteName.LOGIN &&
          !route?.childRouteName && <Login />}
        {route?.mainRouteName === MainRouteName.LOGIN &&
          route?.childRouteName === LoginChildName.PASSWORD_RESET && (
            <PasswordReset />
          )}
        {route?.mainRouteName === MainRouteName.REGISTER && <Register />}
      </StyledScrollBars>
    </AnimatedContainer>
  )
}

export const AnimatedSwitch: React.FC = () => {
  const { route } = useAccountRouter()
  const screenResolution = useReactiveVar(screenResolutionVar)
  const [lastLocation, setLastLocation] =
    useState<RoutingParams<MainRouteName> | null>(null)
  const [statefulLocation, setStatefulLocation] =
    useState<RoutingParams<MainRouteName> | null>(route)

  useEffect(() => {
    if (
      route?.mainRouteName !== statefulLocation?.mainRouteName ||
      route?.childRouteName !== statefulLocation?.childRouteName
    ) {
      setLastLocation(statefulLocation)
      setStatefulLocation(route)
    }
  }, [route, statefulLocation])

  const isDesktop = screenResolution.width >= breakpoints.largeTabletMisc

  const transitions = useTransition(statefulLocation, {
    from(item) {
      // when animating from compare the item location to the last location not the location we are going to.
      const shouldAnimate = !(
        lastLocation?.mainRouteName === MainRouteName.HELP &&
        item?.mainRouteName === MainRouteName.HELP
      )
      return shouldAnimate
        ? { opacity: 0, left: isDesktop ? '0%' : '-100%' }
        : { opacity: 1, left: isDesktop ? '100%' : '0%' }
    },
    enter: { opacity: 1, left: isDesktop ? '100%' : '0%' },
    leave(item) {
      const shouldAnimate = !(
        route?.mainRouteName === MainRouteName.HELP &&
        item?.mainRouteName === MainRouteName.HELP
      )
      return shouldAnimate
        ? { opacity: 0, left: isDesktop ? '0%' : '-100%' }
        : { opacity: 1, left: isDesktop ? '100%' : '0%' }
    },
    exitBeforeEnter: false,
  })

  return (
    <>
      {isDesktop && <AnimationHider />}
      {transitions((styles, item) => (
        <AnimatedLocation
          key={item?.mainRouteName}
          styles={styles}
          useLeftOffset={isDesktop}
        />
      ))}
    </>
  )
}

import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { BackToOutletListLink } from '@src/components/BackToOutletListLink/BackToOutletListLink'
import { useBreakpoint } from '@src/hooks/useBreakpoint'

import {
  SkeletonBackButtonContainer,
  SkeletonHeaderContainer,
  SkeletonHeaderDetails,
  SkeletonWrapper,
  TitleAndPillContainer,
} from './OutletPageSkeleton.styles'

export const OutletHeaderSkeleton: React.FC = () => {
  const { isMobile, isTablet } = useBreakpoint()

  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <SkeletonWrapper>
        {isMobile && (
          <>
            <SkeletonHeaderContainer>
              <SkeletonHeaderDetails>
                <TitleAndPillContainer>
                  <SkeletonBackButtonContainer>
                    <BackToOutletListLink
                      showContainer={false}
                      showText={false}
                    />
                  </SkeletonBackButtonContainer>
                  <Skeleton width={230} height={50} />
                </TitleAndPillContainer>
                <Skeleton width={250} height={20} />
                <Skeleton width={270} height={20} />
                <Skeleton width={100} height={20} />
              </SkeletonHeaderDetails>
            </SkeletonHeaderContainer>
          </>
        )}
        {isTablet && (
          <>
            <SkeletonHeaderContainer>
              <SkeletonHeaderDetails>
                <TitleAndPillContainer>
                  <SkeletonBackButtonContainer>
                    <BackToOutletListLink
                      showContainer={false}
                      showText={false}
                    />
                  </SkeletonBackButtonContainer>
                  <Skeleton width={80} height={32} borderRadius={20} />
                </TitleAndPillContainer>
                <Skeleton width={400} height={40} />
                <Skeleton width={600} height={30} />
                <Skeleton width={750} height={20} />
              </SkeletonHeaderDetails>
            </SkeletonHeaderContainer>
          </>
        )}

        {!isMobile && !isTablet && (
          <>
            <SkeletonBackButtonContainer>
              <BackToOutletListLink showContainer={false} showText={false} />
            </SkeletonBackButtonContainer>
            <SkeletonHeaderContainer>
              <SkeletonHeaderDetails>
                <Skeleton width={330} height={50} />
                <Skeleton width={430} height={20} />
                <Skeleton width={460} height={20} />
                <Skeleton width={650} height={42} />
              </SkeletonHeaderDetails>
              <Skeleton width={200} height={200} />
            </SkeletonHeaderContainer>
          </>
        )}
      </SkeletonWrapper>
    </SkeletonTheme>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType } from '@src/components/Button'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useHasActiveOrders } from '@src/hooks/useHasActiveOrders'

import {
  EmptyBasketContainer,
  EmptyBasketOuterContainer,
} from './EmptyBasket.styles'

export const EmptyBasket: React.FC = () => {
  const { t } = useTranslation('checkout')
  const accountRouter = useAccountRouter()
  const checkoutRouter = useCheckoutRouter()
  const { hasActiveOrders } = useHasActiveOrders()
  return (
    <EmptyBasketOuterContainer>
      <EmptyBasketContainer>
        <p>{t('empty_basket')}</p>
      </EmptyBasketContainer>
      {hasActiveOrders && (
        <Button
          onClick={() => {
            checkoutRouter.reset()
            accountRouter.setRoute({
              mainRouteName: MainRouteName.ORDERS,
            })
          }}
          buttonType={ButtonType.TERTIARY}
          content={t('view_active_orders')}
        />
      )}
    </EmptyBasketOuterContainer>
  )
}

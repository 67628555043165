import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { CategorySVG } from '@src/components/SVGS/CategorySVG'
import { breakpoints } from '@src/constants/breakpoints'
import { MerchantType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useCategories } from '@src/hooks/useCategories'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { screenResolutionVar } from '@src/models/screenResolution'
import { convertToSlug } from '@src/utils/convertToSlug'

import {
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine,
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet,
} from './queries/__generated__/getSearchOutletsAndCuisinesMobile'
import {
  SearchInputContainer,
  CategoryIconButton,
  Divider,
  StyledCross,
  StyledIconButton,
  StyledSearchBarIcon,
  StyledSearchInput,
  StyledMenuCross,
} from './SearchHeadStyles'

// this is the main search section with the input and category icon but not results
export const SearchHead: React.FC<{
  executeSearch: (searchTerm: string) => void
  setShowCategories?: (showCategories: boolean) => void
  closeSearchResults: () => void
  categories?: getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine[]
  outlets?: getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet[]
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  showMenuSearchResults?: boolean
  isMenuView?: boolean
}> = ({
  executeSearch,
  setShowCategories,
  closeSearchResults,
  categories,
  outlets,
  searchTerm,
  setSearchTerm,
  isMenuView = false,
  showMenuSearchResults = false,
}) => {
  const { width } = useReactiveVar(screenResolutionVar)
  const marketplace = useMarketplace()
  const { t } = useTranslation('serviceNavigation')
  const basketItems = useBasketItems()
  const [query] = useQueryParams({
    checkout: withDefault(StringParam, ''),
  })
  const [isFocused, setIsFocused] = useState(false)
  const { setCategoryNames, selectedCategories } = useCategories()
  const history = useHistory()

  const updateSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
    if (searchTerm.length < 3) {
      closeSearchResults()
    } else {
      executeSearch(searchTerm)
    }
  }

  const categoryIconIndented =
    basketItems.items.length > 0 || query.checkout === 'complete'

  const placeholder = () => {
    if (!isMenuView) {
      if (marketplace.merchantType === MerchantType.RETAIL) {
        return t('search_retail')
      }
      return t('search_restaurant')
    }

    return t('search_for_item')
  }

  return (
    <SearchInputContainer isFocused={isFocused} $isMenuView={isMenuView}>
      {(width < breakpoints.phablet ||
        (width >= breakpoints.phablet && searchTerm.length > 0)) && (
        <StyledIconButton
          $display={true}
          $isMenuView={isMenuView}
          onClick={() => {
            updateSearch('')
          }}
          $startAnimation={false}
        >
          <StyledCross id="styled-cross" />
        </StyledIconButton>
      )}

      {width > breakpoints.tablet &&
        isMenuView &&
        (searchTerm.length > 3 || showMenuSearchResults) && (
          <StyledMenuCross
            onClick={() => {
              updateSearch('')
            }}
          >
            <StyledCross id="styled-cross" />
          </StyledMenuCross>
        )}
      <StyledSearchBarIcon
        $showSearchBar={true}
        id="search"
        $startAnimation={false}
        $isFocused={isFocused}
        $isMenuView={isMenuView}
      />
      <StyledSearchInput
        $startAnimation={false}
        $showSearchBar={false}
        $isMenuView={isMenuView}
        onFocus={e => {
          setIsFocused(true)
          updateSearch(e.target.value)
        }}
        onKeyDown={e => {
          // search for closest match
          if (e.key === 'Enter' && outlets && categories) {
            if (categories[0]) {
              setCategoryNames({
                categories: [
                  ...selectedCategories.map(({ name }) => name),
                  categories[0].name,
                ],
              })
              setSearchTerm('')
              closeSearchResults()
            } else if (outlets[0]) {
              const url = `/${marketplace.urlPath}/${
                outlets[0].id
              }/${convertToSlug(outlets[0].displayName)}`
              history.push(url)
              setSearchTerm('')
              closeSearchResults()
            }
          }
        }}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder()}
        value={searchTerm}
        onChange={e => {
          updateSearch(e.target.value)
        }}
      />

      {categories && setShowCategories && (
        <>
          <Divider showOnRight={categoryIconIndented} />
          <CategoryIconButton
            $showSearchBar={true}
            $startAnimation={false}
            onClick={() => setShowCategories(true)}
          >
            <CategorySVG id="category" />
          </CategoryIconButton>
        </>
      )}
    </SearchInputContainer>
  )
}

import { animated, useSpring } from '@react-spring/web'
import throttle from 'lodash/throttle'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  featuredOutletsListRoutes,
  segmentOutletListRoutes,
} from '@src/constants/routes'
import { MainRouteName } from '@src/hooks/useAccountRouter'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useHasActiveOrders } from '@src/hooks/useHasActiveOrders'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { imageJitURL } from '@src/utils/imageJitURL'

import { BasketButton } from './BasketButton/BasketButton'
import { ServiceNavigation } from './ServiceNavigation/ServiceNavigation'
import {
  MarketplaceLogo,
  LogoContainer,
  RightIconsContainer,
  ServiceNavigationContainer,
  LogoAndBurgerButtonContainer,
  ActiveOrderDot,
  Container,
  MenuContainer,
  BurgerButton,
  BurgerMenuIcon,
} from './SiteHeader.styles'

import { CategoriesContent } from '../CategoriesModal/CategoriesContent'
import { Modal, ModalPosition } from '../Modal/Modal'

export const SiteHeader: React.FC = () => {
  const { urlPath, ...marketplace } = useMarketplace()
  const { isWideDesktop } = useBreakpoint()

  const { hasActiveOrders } = useHasActiveOrders()

  const [showCategories, setShowCategories] = useState(false)

  const isOutletListRoute = useRouteMatch([
    `/:merchantCategory(${urlPath})`,
    `/:deliveryZone/:merchantCategory(${urlPath})`,
  ])

  const isSegmentOutletListRoute = useRouteMatch([
    ...featuredOutletsListRoutes,
    ...segmentOutletListRoutes,
  ])
  const [scrollData, setScrollData] = useState({
    lastScrollY: 0,
    serviceNavigationIsVisible: true,
  })
  // track the scroll position so we can compare it to the last position to transition the ServiceNavigation
  // when scrolling up and down
  useEffect(() => {
    if (window.onscroll === null) {
      window.onscroll = throttle(
        () => {
          const newPosition = window.scrollY
          setScrollData({
            lastScrollY: newPosition,
            serviceNavigationIsVisible:
              newPosition < 200 || newPosition < scrollData.lastScrollY,
          })
        },
        250,
        { leading: true, trailing: true }
      )
    }

    return () => {
      window.onscroll = null
    }
  }, [])
  const hideOnScrollSpring = useSpring({
    translateY: scrollData.serviceNavigationIsVisible ? '0' : '-100%',
  })
  const AnimatedServiceNavigationContainer = animated(
    ServiceNavigationContainer
  )

  const showServiceNavigation =
    isOutletListRoute?.isExact || isSegmentOutletListRoute?.isExact

  return (
    <>
      {showCategories && (
        <Modal
          isOpen={showCategories}
          position={ModalPosition.CATEGORIES}
          closeType={{
            type: 'clickOutsideOnly',
            handleClose: () => setShowCategories(false),
          }}
        >
          <CategoriesContent setShowCategories={setShowCategories} />
        </Modal>
      )}
      <Container>
        <MenuContainer>
          <LogoAndBurgerButtonContainer>
            <BurgerButton route={{ mainRouteName: MainRouteName.MENU }}>
              {hasActiveOrders && <ActiveOrderDot />}
              <BurgerMenuIcon id="profile" dataTestId="burger-menu-icon" />
            </BurgerButton>
            <LogoContainer to="/">
              <MarketplaceLogo
                role="img"
                aria-label={`${marketplace.name} logo`}
                imageUrl={imageJitURL(marketplace.logoImage, {
                  resize: {
                    width: 100,
                    height: 50,
                    fit: 'outside',
                  },
                })}
              />
            </LogoContainer>
            {!isWideDesktop && (
              <RightIconsContainer>
                <BasketButton />
              </RightIconsContainer>
            )}
          </LogoAndBurgerButtonContainer>
        </MenuContainer>
        {showServiceNavigation && (
          <AnimatedServiceNavigationContainer style={hideOnScrollSpring}>
            <ServiceNavigation />
          </AnimatedServiceNavigationContainer>
        )}
      </Container>
    </>
  )
}

import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  Header,
  HeaderContainer,
  NonIdealSearchContainer,
} from './MenuItemSearchResults.styles'

import { isFinalElementInBreakpoint } from '../isFinalElementInBreakpoint'
import { ItemsContainer, ItemContainer } from '../MenuGroup/MenuGroup.styles'
import { MenuItem } from '../MenuItem/MenuItem'

export const MenuItemSearchResults: React.VFC<{
  outletId: string
  menuSearchIds: string[]
  searchTerm: string
}> = ({ outletId, menuSearchIds, searchTerm }) => {
  const { width } = useReactiveVar(screenResolutionVar)
  const { displayPermanentCheckout } = useCheckoutRouter()

  const { t } = useTranslation('serviceNavigation')

  return (
    <div>
      <HeaderContainer>
        <Header>{t('search_results', { searchTerm: searchTerm })}</Header>
      </HeaderContainer>
      {menuSearchIds.length ? (
        <ItemsContainer>
          {menuSearchIds.map((menuItemId, index) => (
            <ItemContainer key={menuItemId}>
              <MenuItem
                finalElement={isFinalElementInBreakpoint(
                  menuSearchIds.length,
                  index,
                  width,
                  displayPermanentCheckout
                )}
                outletId={outletId}
                menuItemId={menuItemId}
              />
            </ItemContainer>
          ))}
        </ItemsContainer>
      ) : (
        <NonIdealSearchContainer>
          {t('nothing_matches_search')}
        </NonIdealSearchContainer>
      )}
    </div>
  )
}

import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { BackToOutletListLink } from '@src/components/BackToOutletListLink/BackToOutletListLink'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { clearNonBasketOutletFulfilments } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/clearNonBasketFulfilments'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'
import { sortOutlets } from '@src/utils/sortOutlets'

import { getOutletSearchVariables } from '../OutletList/getOutletSearchVariables'
import { OutletCards } from '../OutletList/OutletCards'
import {
  HeaderContainer,
  OutletsOuterContainer,
} from '../OutletList/OutletList.styles'
import { OutletListSkeleton } from '../OutletList/OutletListSkeleton'
import { outletListDocument } from '../OutletList/queries/__generated__/outletList.graphql-interface'

export const FeaturedOutletList: React.FC = () => {
  const { t: copyTranslations } = useTranslation('copy')
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()

  useEffect(() => {
    clearNonBasketOutletFulfilments()
  }, [])

  const variables = getOutletSearchVariables({
    fulfilmentFilter: fulfilmentFilter.data,
    marketplaceId: marketplace.id,
    featured: true,
  })

  const { data, loading, error } = useQuery(
    outletListDocument,
    variables ? { variables } : { skip: true }
  )

  if (loading) {
    return <OutletListSkeleton />
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }

  const outlets = sortOutlets(
    data.outlets.map(outlet => parseOutletDates(outlet)),
    fulfilmentFilter.data
  )

  return (
    <OutletsOuterContainer>
      <BackToOutletListLink showContainer />
      <HeaderContainer>
        {copyTranslations('now_on', {
          marketplaceName: marketplace.name,
        })}
      </HeaderContainer>
      <OutletCards outlets={outlets}></OutletCards>
    </OutletsOuterContainer>
  )
}

import { LoyaltyCardDiscountStrategy } from '@src/graphql-types'
import { CustomerDetailsAndAddresses_customerDetails_loyaltyCards_discount } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddresses'

import { UseBasketTotalsResponseData } from '../../../hooks/useTotals/useBasketTotals'

interface AmountToSpendToEarnStamp {
  basketTotals?: UseBasketTotalsResponseData
  discount?: CustomerDetailsAndAddresses_customerDetails_loyaltyCards_discount
  discountStrategy: LoyaltyCardDiscountStrategy
}
export const amountToSpendToEarnAStamp = ({
  basketTotals,
  discount,
  discountStrategy,
}: AmountToSpendToEarnStamp): number => {
  if (!basketTotals?.checkoutBasketTotals) {
    return 0
  }

  const { appliedDiscount } = basketTotals.checkoutBasketTotals || {}
  const basketItemsTotal = basketTotals.basketItemsTotal || 0
  const discountAmount = appliedDiscount?.discount?.discountAmount || 0
  const loyaltyCardDiscount =
    appliedDiscount?.discount?.loyaltyCard?.netTotal || 0

  // check if the basket subtotal is below the minimum required subtotal for the discount
  if (
    discount?.minimumSubtotalGross &&
    basketItemsTotal < discount.minimumSubtotalGross
  ) {
    return discount.minimumSubtotalGross - basketItemsTotal
  }

  // If the loyalty card has a basket subtotal discount strategy
  //  check that any applied discount isn't bigger than the subtotal so that the stamp value isn't zero
  if (discountStrategy === 'BASKET_SUBTOTAL_DISCOUNT') {
    // Loyalty card discounts work the same as a fixed discount, so we use the net total
    if (loyaltyCardDiscount > 0) {
      if (basketItemsTotal < loyaltyCardDiscount) {
        return loyaltyCardDiscount - basketItemsTotal
      }

      // if the basket subtotal is exactly the same as the amount of discount taken off
      // you should still have to spend more because the value of the stamp is still zero
      if (basketItemsTotal === loyaltyCardDiscount) {
        return 1
      }
    }

    // if its a regular discount passed through, check the discount amount
    if (discountAmount > 0) {
      if (basketItemsTotal < discountAmount) {
        return discountAmount - basketItemsTotal
      }
      if (basketItemsTotal === discountAmount) {
        return 1
      }
    }
  }

  //no extra spend needed
  return 0
}

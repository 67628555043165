import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type CustomerDetailsAndAddressesQueryVariables = Types.Exact<{
  outletId?: Types.InputMaybe<Types.Scalars['ID']>
}>

export type CustomerDetailsAndAddressesQuery = {
  customerDetails: {
    __typename: 'Customer'
    id: string
    registeredAt: string | null
    email: string
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    optOutMarketingSMS: boolean | null
    optOutMenuMarketing: boolean | null
    optOutMarketingPush: boolean | null
    optOutMarketingEmail: boolean | null
    optOutPromotionMarketing: boolean | null
    optOutNewRestaurantMarketing: boolean | null
    deliveryAddresses: Array<{
      __typename: 'Address'
      id: string
      name: string
      firstLine: string
      secondLine: string | null
      thirdLine: string | null
      city: string
      postcode: string
      countryCode: Types.CountryCodeType
      default: boolean
      acceptsDelivery: boolean
    }>
    loyaltyCards: Array<{
      __typename: 'LoyaltyCard'
      id: string
      active: boolean
      loyaltyCardIcon: string
      loyaltyCardColor: string
      termsAndConditions: string
      discountStrategy: Types.LoyaltyCardDiscountStrategy
      allowCashOrders: boolean
      canStampBeEarnedToday: boolean | null
      netTotal: number | null
      requiredStamps: number
      loyaltyCardStamps: Array<{
        __typename: 'LoyaltyCardStamp'
        id: string
        stampClaimedOnOrder: { __typename: 'Order'; id: string } | null
      }> | null
      discount: {
        __typename: 'Discount'
        id: string
        name: string
        discountAmount: number | null
        discountPercentage: number | null
        customerEmail: string | null
        daysOfWeek: Array<number> | null
        customerEnrolled: boolean
        endDate: string | null
        startDate: string | null
        minimumSubtotalGross: number | null
      }
    }> | null
  }
}

export const CustomerDetailsAndAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerDetailsAndAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registeredAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingSMS' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMenuMarketing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingPush' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutPromotionMarketing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutNewRestaurantMarketing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registeredAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thirdLine' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptsDelivery' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'outletId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'outletId' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCards' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'outletId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'outletId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardIcon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termsAndConditions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountStrategy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowCashOrders' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canStampBeEarnedToday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredStamps' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardStamps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stampClaimedOnOrder',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountAmount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'discountPercentage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daysOfWeek' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEnrolled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'minimumSubtotalGross',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerDetailsAndAddressesQuery,
  CustomerDetailsAndAddressesQueryVariables
>

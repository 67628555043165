import { breakpoints } from '@src/constants/breakpoints'

const isElementOnFinalRow = (
  elementsPerRow: number,
  totalElements: number,
  indexOfElement: number
): boolean => {
  const lastRowIndex = Math.ceil(totalElements / elementsPerRow) - 1
  const lastRowStartIndex = lastRowIndex * elementsPerRow
  const lastRowEndIndex =
    lastRowStartIndex + (totalElements % elementsPerRow || elementsPerRow) - 1

  return (
    indexOfElement >= lastRowStartIndex && indexOfElement <= lastRowEndIndex
  )
}

export const isFinalElementInBreakpoint = (
  totalElements: number,
  indexOfElement: number,
  width: number,
  displayPermanentCheckout: boolean
): boolean => {
  // this has to be done outside of styled-components as last-child will not work with repeated menu item groups

  // always remove border on final element
  if (width < breakpoints.tablet) {
    return indexOfElement + 1 === totalElements
  }

  // tablet: rows of 2
  if (width < breakpoints.largeTablet) {
    return isElementOnFinalRow(2, totalElements, indexOfElement)
  }

  // desktop: rows of 3
  if (width < breakpoints.wideDesktop || displayPermanentCheckout) {
    return isElementOnFinalRow(3, totalElements, indexOfElement)
  }

  return isElementOnFinalRow(4, totalElements, indexOfElement)
}

import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import {
  CardOuterContainer,
  DealsAndOffersCardContainer,
  SkeletonDealsContainer,
} from './OutletPageSkeleton.styles'

export const DealsAndOffersSkeleton: React.FC = () => {
  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <SkeletonDealsContainer>
        <Skeleton width={160} height={31} />
        <DealsAndOffersCardContainer>
          <CardOuterContainer>
            <Skeleton width={289} height={88} />
          </CardOuterContainer>
          <CardOuterContainer>
            <Skeleton width={289} height={88} />
          </CardOuterContainer>
          <Skeleton width={289} height={88} />
        </DealsAndOffersCardContainer>
      </SkeletonDealsContainer>
    </SkeletonTheme>
  )
}

import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getSingleOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type getSingleOrderQuery = {
  myOrder: {
    __typename: 'Order'
    id: string
    orderNumber: string
    orderStatus: Types.OrderStatus | null
    cleanOrderStatus: Types.CleanOrderStatus
    asap: boolean | null
    createdAt: string
    updatedAt: string
    discount: unknown | null
    cancellationNotes: string | null
    deliveryNetworkJobTrackerURL: string | null
    paymentMethod: Types.PaymentMethod
    subtotalNet: number | null
    subtotalGross: number | null
    customerTaxes: number
    partnerCharge: number | null
    grossTotal: number
    customerDeliveryNotes: string | null
    customerOrderNotes: string | null
    narrowFulfilmentMethod: Types.NarrowFulfilmentMethodInputType
    fulfillmentCharge: number | null
    discountAmount: number | null
    selectedDeliverySlot: string | null
    estimatedDeliveryDate: string | null
    estimatedCompletionTime: string | null
    loyaltyCardStampInfo: Array<{
      __typename: 'LoyaltyCardStampOrderInfo'
      loyaltyCardIcon: string
      loyaltyCardName: string
      totalStampsText: string
      stampsEarnedUpToThisOrder: number
      amountEarnedOnOrder: number
    }> | null
    payment:
      | {
          __typename: 'CardPayment'
          brand: string
          last4: string
          exp_month: number
          exp_year: number
          errorMessage: string | null
        }
      | { __typename: 'CashPayment'; errorMessage: string | null }
      | { __typename: 'InPersonCardPayment'; errorMessage: string | null }
      | {
          __typename: 'OtherPayment'
          errorMessage: string | null
          paymentMethodName: string
        }
    groupedItems: Array<{
      __typename: 'GroupedOrderItem'
      id: string
      name: string
      singleItemNotes: string | null
      quantity: number
      totalPrice: number
      isAddOnItem: boolean | null
      menuItemId: string
      optionItems: Array<{
        __typename: 'OrderOptionItem'
        id: string
        name: string | null
        price: number | null
      }>
    }>
    customerDeliveryAddress: {
      __typename: 'Address'
      id: string
      firstLine: string
      secondLine: string | null
      thirdLine: string | null
      city: string
      postcode: string
    } | null
    outlet: {
      __typename: 'Outlet'
      id: string
      displayName: string
      isVATregistered: boolean
      outletLogoOverride: string | null
      outletPhone: string | null
      active: boolean | null
      restaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
        image: string | null
      }
      outletAddress: {
        __typename: 'Address'
        id: string
        firstLine: string
        secondLine: string | null
        thirdLine: string | null
        city: string
        postcode: string
      }
    }
    customerBillingAddress: {
      __typename: 'Address'
      id: string
      firstLine: string
      secondLine: string | null
      thirdLine: string | null
      city: string
      postcode: string
    } | null
    deliveryWindow: {
      __typename: 'DeliveryWindow'
      start: string | null
      end: string | null
    } | null
  }
  customerDetails: {
    __typename: 'Customer'
    id: string
    firstName: string | null
    lastName: string | null
  }
}

export const getSingleOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSingleOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cleanOrderStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'asap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancellationNotes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryNetworkJobTrackerURL' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethod' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCardStampInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardIcon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalStampsText' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'stampsEarnedUpToThisOrder',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountEarnedOnOrder' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CashPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errorMessage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CardPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brand' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last4' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exp_month' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exp_year' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errorMessage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'InPersonCardPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errorMessage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'OtherPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errorMessage' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paymentMethodName',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupedItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'singleItemNotes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isAddOnItem' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'menuItemId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subtotalNet' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotalGross' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerTaxes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerCharge' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'grossTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerDeliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thirdLine' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerDeliveryNotes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerOrderNotes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outlet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isVATregistered' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outletLogoOverride' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restaurant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outletAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstLine' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondLine' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thirdLine' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postcode' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outletPhone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerBillingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thirdLine' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'narrowFulfilmentMethod' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentCharge' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountAmount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedDeliverySlot' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryWindow' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimatedDeliveryDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimatedCompletionTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getSingleOrderQuery, getSingleOrderQueryVariables>

import styled from 'styled-components'

import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'

export const StickyHeader = styled.div<{ compactStyle: boolean }>`
  padding-top: 60px;
  top: 0;
  left: 0;
  transition: ease-in-out 0.3s;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1;
`

export const HeaderWrapper = styled.div<{
  permanentCheckoutOpen: boolean
  checkoutOpen: boolean
}>`
  display: flex;
  flex-direction: column;
  transition: ease-in-out 0.3s;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    width: ${({ permanentCheckoutOpen }) =>
      permanentCheckoutOpen
        ? `calc(100vw - ${basketWidth.regular}px)`
        : '100%'};
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    width: ${({ permanentCheckoutOpen }) =>
      permanentCheckoutOpen
        ? `calc(100vw - ${basketWidth.superWideDesktop}px)`
        : '100%'};
  }
`

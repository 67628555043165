import React, { useState } from 'react'

import { CategorySVG } from '@src/components/SVGS/CategorySVG'

import {
  CategoryIconButton,
  Divider,
  MobileCategoryCircle,
  StyledCross,
  StyledIconButton,
  StyledSearchBarIcon,
  StyledSearchInput,
} from './SearchHeadStyles'

export const SearchHeadMobile: React.FC<{
  showSearchBar: boolean
  startAnimation: boolean
  placeholder: string
  startClosingAnimation: () => void
  executeSearch: (searchTerm: string) => void
  setShowCategories?: (showCategories: boolean) => void
  closeSearchResults: () => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  isMenuView?: boolean
}> = ({
  showSearchBar,
  startAnimation,
  placeholder,
  startClosingAnimation,
  executeSearch,
  setShowCategories,
  closeSearchResults,
  searchTerm,
  setSearchTerm,
  isMenuView = false,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const updateSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
    if (searchTerm.length < 3) {
      closeSearchResults()
    } else {
      executeSearch(searchTerm)
    }
  }
  return (
    <>
      <StyledSearchInput
        $showSearchBar={showSearchBar}
        $startAnimation={startAnimation}
        $isMenuView={isMenuView}
        onChange={e => {
          updateSearch(e.target.value)
        }}
        onFocus={e => {
          setIsFocused(true)
          updateSearch(e.target.value)
        }}
        onBlur={() => setIsFocused(false)}
        value={searchTerm}
        placeholder={placeholder}
      />
      <StyledSearchBarIcon
        $showSearchBar={showSearchBar}
        $startAnimation={startAnimation}
        $isFocused={isFocused}
        id="search"
        $isMenuView={isMenuView}
      />
      <StyledIconButton
        onClick={() => {
          updateSearch('')
          startClosingAnimation()
        }}
        $display={showSearchBar}
        $startAnimation={startAnimation}
        $isMenuView={isMenuView}
      >
        <StyledCross id="styled-cross" title="Close Search" />
      </StyledIconButton>
      {setShowCategories && (
        <div>
          <MobileCategoryCircle $showSearchBar={showSearchBar}>
            <CategoryIconButton
              $showSearchBar={showSearchBar}
              $startAnimation={startAnimation}
              onClick={() => setShowCategories(true)}
            >
              <CategorySVG id="category" />
            </CategoryIconButton>
          </MobileCategoryCircle>
          {showSearchBar && (
            <CategoryIconButton
              $showSearchBar={showSearchBar}
              $startAnimation={startAnimation}
              onClick={() => setShowCategories(true)}
            >
              <Divider />
              <CategorySVG id="category" />
            </CategoryIconButton>
          )}
        </div>
      )}
    </>
  )
}

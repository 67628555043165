import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { BasketOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/BasketOutletProvider'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'

import { DeliveryAndNotes } from './DeliveryAndNotes'
import { HelpAndSupport } from './HelpAndSupport'
import { LoyaltyCardStamps } from './LoyaltyCardStamps'
import { MenuItems } from './MenuItems'
import { OrderCost } from './OrderCost'
import { OrderDetails } from './OrderDetails'
import { PaymentDetails } from './PaymentDetails'
import { getSingleOrderDocument } from './queries/__generated__/getSingleOrder.graphql-interface'
import { OrderInfoContainer } from './SingleOrder.styles'

export const SingleOrder: React.VFC<{ orderId: string }> = ({ orderId }) => {
  useNotLoggedInRedirect()
  const { t } = useTranslation(['singleOrder'])

  const { data, loading } = useQuery(getSingleOrderDocument, {
    variables: {
      id: orderId,
    },
    // matches list view to ensure latest status is shown
    fetchPolicy: 'cache-and-network',
  })

  if (loading) {
    return <LoadingSpinner text={t('searching_for_order')} />
  }
  if (!data || !data.myOrder) {
    return (
      <BurgerSection header={{ title: t('order_details') }}>
        <div>{t('this_order_doesnt_exist')}.</div>
      </BurgerSection>
    )
  }

  const narrowFulfilmentMethod = data.myOrder.narrowFulfilmentMethod

  const createdAt = new Date(data.myOrder.createdAt)

  const deliveryWindow = data.myOrder.deliveryWindow

  const startWindow: Date | undefined = deliveryWindow?.start
    ? new Date(deliveryWindow.start)
    : undefined
  const endWindow: Date | undefined = deliveryWindow?.end
    ? new Date(deliveryWindow.end)
    : undefined

  const formattedDeliveryDate = data.myOrder.estimatedDeliveryDate
    ? new Date(data.myOrder.estimatedDeliveryDate)
    : undefined

  const formattedCollectionDate = data.myOrder.estimatedCompletionTime
    ? new Date(data.myOrder.estimatedCompletionTime)
    : undefined

  const { myOrder, customerDetails } = data
  return (
    <BurgerSection header={{ title: t('order_details') }}>
      <OrderDetails
        myOrder={myOrder}
        fulfillmentMethod={narrowFulfilmentMethod}
        createdAt={createdAt}
        startWindow={startWindow}
        endWindow={endWindow}
        deliveryDate={formattedDeliveryDate}
        collectionDate={formattedCollectionDate}
      />
      <OrderInfoContainer>
        <MenuItems myOrder={myOrder} />
        <BasketOutletProvider basketOutletIdOverride={myOrder.outlet.id}>
          <OrderCost myOrder={myOrder} />
        </BasketOutletProvider>
      </OrderInfoContainer>
      <DeliveryAndNotes myOrder={myOrder} customerDetails={customerDetails} />
      <LoyaltyCardStamps myOrder={myOrder} />
      <PaymentDetails order={myOrder} />
      <HelpAndSupport myOrder={myOrder} />
    </BurgerSection>
  )
}

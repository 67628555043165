import { formatISO } from 'date-fns'

import { LocationType } from '@src/graphql-types'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'

import { getBusinessSegmentOutletsVariables } from './queries/__generated__/getBusinessSegmentOutlets'

export const getOutletSearchBySegmentVariables = ({
  fulfilmentFilterData,
  marketplaceId,
  businessSegmentId,
  limitOutlets,
}: {
  fulfilmentFilterData: ReturnType<typeof useFulfilmentFilter>['data']
  marketplaceId: string
  businessSegmentId: string
  limitOutlets: boolean
}): getBusinessSegmentOutletsVariables => {
  const fulfilmentMethods = fulfilmentFilterData.nonEmptyFulfilmentMethods

  const preorderDate =
    fulfilmentFilterData.when.type === FulfilmentFilterWhenType.PREORDER
      ? formatISO(fulfilmentFilterData.when.preorderDate)
      : undefined

  const baseInput = {
    businessSegmentId,
    marketplaceId,
    narrowFulfilmentMethods: fulfilmentMethods,
    selectedDate: preorderDate,
    whenType: fulfilmentFilterData.when.type,
    limitOutlets,
  }

  switch (fulfilmentFilterData.where.location.type) {
    case LocationType.EVERYWHERE:
      return {
        fulfilmentMethod: fulfilmentFilterData.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.EVERYWHERE,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilterData.where.location,
      }

    case LocationType.POSTCODE:
      return {
        fulfilmentMethod: fulfilmentFilterData.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.POSTCODE,
          postAndCountryCode: {
            postcode:
              fulfilmentFilterData.where.location.postAndCountryCode.postcode,
          },
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilterData.where.location,
      }

    case LocationType.COORDINATES:
      return {
        fulfilmentMethod: fulfilmentFilterData.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.COORDINATES,
          coordinates: {
            lat: fulfilmentFilterData.where.location.coordinates.lat,
            lng: fulfilmentFilterData.where.location.coordinates.lng,
          },
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilterData.where.location,
      }

    case LocationType.ADDRESS:
      return {
        fulfilmentMethod: fulfilmentFilterData.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.ADDRESS,
          addressId: fulfilmentFilterData.where.location.addressId,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilterData.where.location,
      }

    case LocationType.DELIVERY_ZONE:
      return {
        fulfilmentMethod: fulfilmentFilterData.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.DELIVERY_ZONE,
          deliveryZoneId: fulfilmentFilterData.where.location.zoneId,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilterData.where.location,
      }
  }
}

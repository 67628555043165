import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useBreakpoint } from '@src/hooks/useBreakpoint'

import { DealsAndOffersSkeleton } from './DealsAndOffersSkeleton'
import { OutletHeaderSkeleton } from './OutletHeaderSkeleton'
import {
  MenuDetailSingleRow,
  MenuFlexWrap,
  PriceContainer,
  SkeletonFulfilmentSection,
  SkeletonMenuContainer,
  SkeletonMenuDetails,
  SkeletonMenuItemContainer,
  SkeletonMenuLogoAndPriceContainer,
  SkeletonNavBar,
} from './OutletPageSkeleton.styles'

export const OutletPageSkeleton: React.FC = () => {
  const { isMobile, isTablet, isWideDesktop } = useBreakpoint()

  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <OutletHeaderSkeleton />
      {isMobile && (
        <>
          <SkeletonFulfilmentSection>
            <Skeleton width={250} height={25} />
          </SkeletonFulfilmentSection>
          <SkeletonNavBar>
            <Skeleton width={250} height={25} />
          </SkeletonNavBar>
        </>
      )}

      {isTablet && (
        <>
          <SkeletonFulfilmentSection>
            <Skeleton width={587} height={25} />
          </SkeletonFulfilmentSection>
          <SkeletonNavBar>
            <Skeleton width={587} height={25} />
          </SkeletonNavBar>
        </>
      )}
      {!isMobile && !isTablet && (
        <>
          <SkeletonFulfilmentSection>
            <Skeleton width={523} height={25} />
          </SkeletonFulfilmentSection>
          <SkeletonNavBar>
            <Skeleton width={587} height={25} />
          </SkeletonNavBar>
        </>
      )}

      <DealsAndOffersSkeleton />

      {/* menu section */}
      <SkeletonMenuContainer>
        <Skeleton width={300} height={31} />
        <MenuFlexWrap>
          {Array(9)
            .fill(0)
            .map((_, i) => (
              <SkeletonMenuItemContainer key={i}>
                <SkeletonMenuDetails>
                  <Skeleton width={isWideDesktop ? 200 : 250} height={52} />
                  <MenuDetailSingleRow>
                    <Skeleton width={isWideDesktop ? 200 : 250} height={64} />
                  </MenuDetailSingleRow>
                </SkeletonMenuDetails>
                <SkeletonMenuLogoAndPriceContainer>
                  <Skeleton width={128} height={128} />
                  <PriceContainer>
                    <Skeleton
                      width={100}
                      height={28}
                      borderRadius={'0.75rem'}
                    />
                  </PriceContainer>
                </SkeletonMenuLogoAndPriceContainer>
              </SkeletonMenuItemContainer>
            ))}
        </MenuFlexWrap>
      </SkeletonMenuContainer>
    </SkeletonTheme>
  )
}

import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { debounce } from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'
import { MenuItemTextSearchDocument } from '@src/pages/OutletPage/OutletMenu/queries/__generated__/MenuItemTextSearch.graphql-interface'

import { ExpandButton } from '../../ServiceNavigation/MobileNavigationStyles'
import { SearchHead } from '../SearchHead'
import { SearchHeadMobile } from '../SearchHeadMobile'
import { MobileOutletMenuSearchContainer } from '../SearchHeadStyles'

export const OutletMenuSearch: React.FC<{
  outletId: string
  setMenuSearchIds: (menuSearchIds: string[]) => void
  showMenuSearchResults: boolean
  setShowMenuSearchResults: (showMenuSearchResults: boolean) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}> = ({
  outletId,
  setMenuSearchIds,
  searchTerm,
  showMenuSearchResults,
  setShowMenuSearchResults,
  setSearchTerm,
}) => {
  const { width } = useReactiveVar(screenResolutionVar)
  const { t } = useTranslation('serviceNavigation')
  const [sendSearchQuery, { data }] = useLazyQuery(MenuItemTextSearchDocument)
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)

  const executeSearch = useCallback(
    debounce((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        setShowMenuSearchResults(true)
        void sendSearchQuery({
          variables: {
            outletId: outletId,
            searchQuery: searchTerm,
          },
        })
        ReactPixel.track('Search', {
          search_string: searchTerm,
          content_type: 'product',
        })
      } else {
        setMenuSearchIds([])
        setShowMenuSearchResults(false)
      }
    }, 500),
    [outletId, sendSearchQuery, setMenuSearchIds, setShowMenuSearchResults]
  )

  useEffect(() => {
    if (data?.menuItemTextSearch.length) {
      setMenuSearchIds(
        data?.menuItemTextSearch.map(({ menuItemId }) => menuItemId)
      )
    } else setMenuSearchIds([])
  }, [data?.menuItemTextSearch])

  const handleClose = () => {
    if (startClosingAnimation) {
      startClosingAnimation()
    }
  }

  useEffect(() => {
    if (width >= breakpoints.phablet) {
      setShowSearchBar(false)
      setStartAnimation(false)
    }
  }, [width])

  const startClosingAnimation = () => {
    setStartAnimation(true)
    setShowSearchBar(false)
    setTimeout(() => {
      setStartAnimation(false)
    }, 1000)
  }

  const searchBarPlaceholder = () => {
    if (showSearchBar) return t('search_for_item')
    return ''
  }

  return (
    <>
      {width > breakpoints.tabletMisc ? (
        <SearchHead
          executeSearch={executeSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          closeSearchResults={() => setShowMenuSearchResults(false)}
          isMenuView={true}
          showMenuSearchResults={showMenuSearchResults}
        />
      ) : (
        <MobileOutletMenuSearchContainer>
          <SearchHeadMobile
            executeSearch={executeSearch}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            closeSearchResults={() => setShowMenuSearchResults(false)}
            showSearchBar={showSearchBar}
            startAnimation={startAnimation}
            startClosingAnimation={handleClose}
            placeholder={searchBarPlaceholder()}
            isMenuView={true}
          />
          <ExpandButton
            onClick={() => {
              if (!showSearchBar) {
                setShowSearchBar(true)
              }
            }}
            $display={!showSearchBar}
            $isMenuView={true}
          />
        </MobileOutletMenuSearchContainer>
      )}
    </>
  )
}

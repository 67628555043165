import styled from 'styled-components'

export const Clamp = styled.span<{ clamp: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => (clamp ? clamp : '1')};
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`

export const ClampWithHover = styled.span<{
  clamp: number
  onHoverShow: boolean
}>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.clamp};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  transition: max-height 1s ease-in-out;
  position: relative;
  cursor: pointer;

  &:hover {
    transition: transform 2s ease-in-out;
    ${({ onHoverShow }) => onHoverShow && '-webkit-line-clamp: unset'};
    white-space: normal;
    max-height: 15em;
    white-space: normal;
    max-height: 100%;
  }
`

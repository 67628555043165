import { Formik } from 'formik'
import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonType } from '@src/components/Button'
import { DotSpan } from '@src/components/DotSpan/DotSpan'
import { TextInput } from '@src/components/Inputs'
import { CheckSVG } from '@src/components/SVGS/CheckSVG'
import { Discount } from '@src/graphql-types'
import { useDiscountCode } from '@src/hooks/useDiscountCodes/useDiscountCode'
import { useDiscounts } from '@src/hooks/useDiscounts/useDiscounts'
import { DiscountType } from '@src/hooks/useDiscounts/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'

import {
  ActiveDiscount,
  ActiveDiscountContainer,
  ActiveDiscountName,
  ApplyButton,
  ClearButton,
  Container,
  DiscountForm,
  SpaceBetweenButton,
  SpaceBetweenRow,
  Title,
  VoucherWarning,
  VoucherWarningContainer,
} from './DiscountCodeSection.styles'

export const DiscountCodeSection: React.FC<{
  basketSubTotal: number
  outletId: string
  appliedDiscount: Pick<Discount, 'id' | 'name'> | null
}> = ({ basketSubTotal, outletId, appliedDiscount }) => {
  const marketplace = useMarketplace()
  const { discounts, updateDiscount } = useDiscounts()

  const {
    discountError,
    discountCodeOpen,
    isVoucherExceedingBasketSubtotal,
    setIsVoucherExceedingBasketSubtotal,
    loading,
    setDiscountCodeOpen,
    handleSubmit,
  } = useDiscountCode({
    basketSubTotal,
    outletId,
    marketplaceId: marketplace.id,
    onDiscountApplied: id =>
      updateDiscount({
        type: DiscountType.VOUCHER_DISCOUNT,
        data: id,
      }),
  })

  const { t } = useTranslation('discounts')

  return (
    <Container>
      {appliedDiscount && appliedDiscount.id === discounts.voucherDiscountId ? (
        <SpaceBetweenRow>
          <ActiveDiscountContainer>
            <CheckSVG id="check" />
            <ActiveDiscount>{t('discount_applied')}</ActiveDiscount>
            <DotSpan />
            <ActiveDiscountName>{appliedDiscount.name}</ActiveDiscountName>
          </ActiveDiscountContainer>
          <ClearButton
            onClick={() => {
              updateDiscount({
                type: DiscountType.VOUCHER_DISCOUNT,
                data: null,
              })
              setIsVoucherExceedingBasketSubtotal(false)
            }}
            id={t('clear')}
          />
        </SpaceBetweenRow>
      ) : (
        <SpaceBetweenButton
          onClick={() => setDiscountCodeOpen(!discountCodeOpen)}
        >
          <Title>{t('have_a_discount')}</Title>
        </SpaceBetweenButton>
      )}
      {discountCodeOpen && (
        <Formik initialValues={{ discount: '' }} onSubmit={handleSubmit}>
          <DiscountForm>
            <TextInput
              name="discount"
              label={t('enter_discount_code')}
              disabled={loading}
              autoComplete="off"
              fullWidth
              toUpperCase
              error={discountError}
            />
            <ApplyButton
              content={toUpper(t('apply'))}
              type="submit"
              disabled={loading}
              buttonType={ButtonType.SECONDARY}
            />
          </DiscountForm>
        </Formik>
      )}
      {isVoucherExceedingBasketSubtotal && (
        <VoucherWarningContainer>
          <VoucherWarning>{t('discount_exceeds_total')}</VoucherWarning>
        </VoucherWarningContainer>
      )}
    </Container>
  )
}

import styled, { css } from 'styled-components'

import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

export const Container = styled.div`
  background-color: white;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;

  border-bottom: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;
`

export const CapsuleContainer = styled.div`
  padding: 8px 18px 0px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  ${removeScrollbar};
`

export const FilterOverlay = styled.div`
  position: absolute;
  height: 52px;
  top: 11px;
  width: 52px;
  background-color: white;
  z-index: 1;
  right: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0)
  );
`

export const Capsule = styled.button<{ active: boolean }>`
  user-select: none;
  width: auto;
  white-space: nowrap;
  font-size: 18px;
  height: 100%;
  font-weight: 700;
  padding: ${({ active }) => (active ? `8px 0px 11px` : '8px 0px 14px')};
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: ${({ active, theme }) =>
    active ? `3px solid ${theme.colors.brand}` : 'none'};
  margin: 0px 16px;
  color: ${({ theme }) => theme.colors.brand};
  text-decoration: none;
  background: none;
  display: flex;
  align-items: center;
  border-radius: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 14px;
    :first-child {
      margin: 0px 16px 0px 0px;
    }
  }

  ${({ active, theme }) =>
    !active &&
    css`
      &:hover {
        border-bottom: 3px solid transparent;
        color: ${theme.colors.brandHighlight};
        padding: 8px 0px 11px;
      }
    `}
`

export const OutletMenuSearchContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-right: 16px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    margin-right: ${basketWidth.superWideDesktop}px;
  }
`
